import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';


import Config from 'configuration';
import { menuClose } from 'actions/MenuActions';

import {
  menuStyles,
  menuItemContainerStyles,
  menuItemAreaStyles,
  menuItemStyles,
  openMenu,
  closeMenu
} from './styles.scss';

const Menu = ({ dispatch, isMenuOpen }) => {

  const [openingMenu, setOpeningMenu] = React.useState(false);

  if(isMenuOpen !== openingMenu){
    setOpeningMenu(isMenuOpen);
  }

  // React.useEffect(() => {window.addEventListener('resize', (event) => {
  //   if(window.innerWidth >= 768 && isMenuOpen === true ){
  //     setOpeningMenu(false);
  //   }
  // })}, [])

  return (
    <div
      className={menuStyles + ` ${openingMenu ? openMenu : closeMenu}`}
    >
      <div className={menuItemContainerStyles}>
        <div className={menuItemAreaStyles}>
          {
            Config.get('menu.menuItems').map((menuItem, i) => (
              <div
                key={i}
                className={menuItemStyles}
              >
                <Link
                  to={menuItem.route}
                  onClick={() => dispatch(menuClose())}
                >
                  {menuItem.title}
                </Link>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

Menu.propTypes = {
  dispatch: PropTypes.func,
  isMenuOpen: PropTypes.bool,
};

const mapStateToProps = state => ({
  isMenuOpen: state.isMenuOpen,
});

export default compose(
  connect(mapStateToProps),
)(Menu);
