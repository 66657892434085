import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';

import Config from 'configuration';
import {
  setThemeLight,
  setPageThemeLight,
  hamburgerClose,
} from 'actions/NavBarActions';
import {
  menuClose,
} from 'actions/MenuActions';
import Markdown from 'components/Markdown';
import PageHeader from 'components/PageHeader';

import {
  aboutStyles,
  background
} from './styles.scss';



const AboutPage = ({ dispatch }) => {

  React.useEffect(() => {
    dispatch(setThemeLight());
    dispatch(setPageThemeLight());
    dispatch(hamburgerClose());
    dispatch(menuClose());
  }, [])

  const url = require('../../../assets/content/about/about.md');


  return (
    <div className={background}>
      <Container
        fluid
        className={aboutStyles}
      >
        <Row>
          <Col md="2" />
          <Col md="8">
            <PageHeader
              title={Config.get('about.header.title')}
              subtitle={(Config.get('about.header.subtitle'))}
            />
            <Markdown
              url={url}
            />
          </Col>
          <Col md="2" />
        </Row>
      </Container>
    </div>
  );
};

AboutPage.propTypes = {
  dispatch: PropTypes.func,
};

const mapStateToProps = () => ({});

export default compose(
  connect(mapStateToProps),
)(AboutPage);
