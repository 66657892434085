/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import marked from 'marked';
import DOMPurify from "dompurify";
import Config from 'configuration';

import { cacheContent } from 'actions/MarkdownActions';

import { markdownStyles } from './styles.scss';

class Markdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: '',
    };
  }

  componentDidMount() {
    const {
      cachedContent,
      contentKey,
      dispatch,
      url,
    } = this.props;

    // if (cachedContent[contentKey]) {
    //   this.setState({ content: cachedContent[contentKey] });
    // } else {
    // const urll = (Config.get('about.markdown.url'));

    // console.log('url', url)
    // const realUri = require(`${url}`)

        const markedContent = marked(url);
        this.setState({ content: markedContent });

    // console.log('reak', realUri)
    // fetch(encodeURI(realUri))
    //   .then(result => result.text())
    //   .then(result => {
    //     console.log('resu', result)
    //     let clean = DOMPurify.sanitize( dirty , {USE_PROFILES: {html: true}} );
    //     const markedContent = marked(clean);
    //     this.setState({ content: markedContent });
    //     // dispatch(cacheContent(contentKey, markedContent));
    //   })
    //   .catch(() => { });
    // }
  }

  render() {
    return (
      <div
        className={markdownStyles}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(this.state.content),
        }}
      />
    );
  }
}

Markdown.propTypes = {
  contentKey: PropTypes.string,
  url: PropTypes.string,
};

const mapStateToProps = ({ }) => ({});

export default compose(
  connect(mapStateToProps),
)(Markdown);
